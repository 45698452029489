<template>
  <div>
    <v-row class="pa-8 px-8">
      <v-spacer></v-spacer>
      <v-btn
        :loading="isLoadingDownloadPdf"
        class="mr-2"
        color="primary"
        dark
        @click="downloadPdf()"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <title>file-pdf-box</title>
          <path
            d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3M9.5 11.5C9.5 12.3 8.8 13 8 13H7V15H5.5V9H8C8.8 9 9.5 9.7 9.5 10.5V11.5M14.5 13.5C14.5 14.3 13.8 15 13 15H10.5V9H13C13.8 9 14.5 9.7 14.5 10.5V13.5M18.5 10.5H17V11.5H18.5V13H17V15H15.5V9H18.5V10.5M12 10.5H13V13.5H12V10.5M7 10.5H8V11.5H7V10.5Z"
          />
        </svg>
      </v-btn>
      <v-btn
        :loading="isLoadingDownload"
        class="mr-2"
        color="primary"
        dark
        @click="downloadExcel()"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <title>microsoft-excel</title>
          <path
            d="M21.17 3.25Q21.5 3.25 21.76 3.5 22 3.74 22 4.08V19.92Q22 20.26 21.76 20.5 21.5 20.75 21.17 20.75H7.83Q7.5 20.75 7.24 20.5 7 20.26 7 19.92V17H2.83Q2.5 17 2.24 16.76 2 16.5 2 16.17V7.83Q2 7.5 2.24 7.24 2.5 7 2.83 7H7V4.08Q7 3.74 7.24 3.5 7.5 3.25 7.83 3.25M7 13.06L8.18 15.28H9.97L8 12.06L9.93 8.89H8.22L7.13 10.9L7.09 10.96L7.06 11.03Q6.8 10.5 6.5 9.96 6.25 9.43 5.97 8.89H4.16L6.05 12.08L4 15.28H5.78M13.88 19.5V17H8.25V19.5M13.88 15.75V12.63H12V15.75M13.88 11.38V8.25H12V11.38M13.88 7V4.5H8.25V7M20.75 19.5V17H15.13V19.5M20.75 15.75V12.63H15.13V15.75M20.75 11.38V8.25H15.13V11.38M20.75 7V4.5H15.13V7Z"
          />
        </svg>
      </v-btn>
    </v-row>
    <v-card>
      <v-card-title>
        <v-card-title>
          <h5 class="flex-grow-1">Información de dispositivos</h5>
        </v-card-title>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" lazy-validation @submit.prevent="searchDeviceData()">
          <v-row>
            <v-col
              v-if="isAdmin"
              class="d-flex"
              cols="12"
              md="2"
              sm="6"
              xs="12"
            >
              <v-select
                v-model="userN1SelectedId"
                :items="usersN1"
                item-value="id"
                item-text="name"
                clas="py-0"
                label="User N1"
                outlined
                :rules="[(v) => !!v || 'Uuario N1 es obligatorio']"
              ></v-select>
              <!-- @change="changeService()" -->
            </v-col>
            <v-col class="d-flex" cols="12" md="2" sm="6" xs="12">
              <v-select
                v-model="serviceSelected"
                :items="services"
                item-value="id"
                item-text="name"
                clas="py-0"
                label="SERVICIO"
                outlined
                :rules="[(v) => !!v || 'Servicio es obligatorio']"
                @change="changeService()"
              ></v-select>
            </v-col>
            <v-col class="d-flex" cols="12" md="2" sm="6" xs="12">
              <v-select
                v-model="typeServiceSlected"
                :items="typeServices"
                item-text="value"
                item-value="id"
                clas="py-0"
                label="TIPO"
                :rules="[(v) => !!v || 'Tipo de producto es obligatorio']"
                outlined
              ></v-select>
            </v-col>

            <!-- <v-row class="pb-1"> -->
            <!-- date start -->
            <v-col class="d-flex" cols="12" md="2" sm="6" xs="12">
              <v-dialog
                ref="dialogStart"
                v-model="modalDateRangeStart"
                :return-value.sync="dateStart"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateStart"
                    append-icon="mdi-calendar-start"
                    label="Fecha inicio"
                    outlined
                    placeholder="Fecha inicio"
                    v-bind="attrs"
                    :rules="[(v) => !!v || 'Fecha de inicio es obligatorio']"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dateStart"
                  scrollable
                  :max="maxDateStart"
                  locale="es-pe"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="modalDateRangeStart = false"
                    >Cancelar</v-btn
                  >
                  <v-btn text color="primary" @click="saveDateStart()"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-dialog>
            </v-col>

            <!-- date end -->
            <v-col class="d-flex" cols="12" md="2" sm="6" xs="12">
              <v-dialog
                ref="dialogEnd"
                v-model="modalDateRangeEnd"
                :return-value.sync="dateEnd"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateEnd"
                    append-icon="mdi-calendar-end"
                    class="flex-grow-1 mr-md-2"
                    label="Fecha fin"
                    readonly
                    outlined
                    clearable
                    placeholder="Fecha fin"
                    v-bind="attrs"
                    :rules="[(v) => !!v || 'Fecha fin es obligatorio']"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dateEnd"
                  scrollable
                  :min="minDateEnd"
                  locale="es-pe"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modalDateRangeEnd = false"
                    >Cancelar</v-btn
                  >
                  <v-btn text color="primary" @click="saveDateEnd()">OK</v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>

            <!-- btn search -->
            <v-col class="d-flex" cols="12" md="2" sm="6" xs="12">
              <v-btn
                class="flex-grow-1"
                height="56px"
                color="secundary"
                type="submit"
              >
                Buscar
              </v-btn>
            </v-col>
            <!-- </v-row> -->
          </v-row>
        </v-form>

        <div id="table-reports">
          <v-data-table :items="items" :headers="headers">
            <template v-slot:[`item.telemetria`]="{}">
              <v-icon>fa-check</v-icon>
            </template>
            <template v-slot:[`item.id_user_n2`]="{ item }">
              {{ item.user.id_usuario }}
            </template>
            <template v-slot:[`item.user_n2`]="{ item }">
              {{ item.user.email }}
            </template>
            <template v-slot:[`item.coefficient`]="{ item }">
              {{ item.coefficient }}
            </template>
            <template v-slot:[`item.tipo`]="{ item }">
              <span
                v-if="
                  item.device_product.telecontrol === false &&
                  item.device_product.telemetry === true
                "
                >Medición</span
              >
              <span
                v-if="
                  item.device_product.telecontrol === true &&
                  item.device_product.telemetry === false
                "
                >Telecontrol</span
              >
              <span
                v-if="
                  item.device_product.telecontrol === true &&
                  item.device_product.telemetry === true
                "
                >Medición + Telecontrol</span
              >
            </template>
            <template v-slot:[`item.createdAt`]="{ item }">
              {{ moment(item.createdAt).format("YYYY-MM-DD hh:mm:ss") }}
            </template>
            <template v-slot:[`item.pulsos`]="{ item }">
              <span v-if="hasPulsos(item)"
                >{{
                  parseFloat(
                    (
                      item.device_data[item.device_data.length - 1].pulsos -
                      item.device_data[0].pulsos
                    ).toFixed(3)
                  )
                }}
              </span>
              <span v-else>0</span>
            </template>
            <template v-slot:[`item.actuador`]="{ item }">
              <span
                v-if="
                  item.device_product.telecontrol === false &&
                  item.device_product.telemetry === true
                "
              >
                NA
              </span>
              <span v-else>
                <!-- <span v-if="hasStatusControl(item)">Abierto </span>
                <span v-else>Cerrado </span> -->
                <span>{{ hasStatusControl2(item) }}</span>
              </span>
            </template>
            <template v-slot:[`item.value_in_momment`]="{ item }">
              <span v-if="hasValue_in_momment(item)"
                >{{
                  parseFloat(
                    (
                      item.device_data[item.device_data.length - 1]
                        .value_in_momment - item.device_data[0].value_in_momment
                    ).toFixed(3)
                  ) +
                  " (" +
                  unidad +
                  ")"
                }}
              </span>
              <span v-else>0</span>
            </template>
            <template v-slot:[`item.caudal`]="{ item }">
              <span v-if="hasCaudal(item)"
                >{{
                  parseFloat(
                    (
                      item.device_data[item.device_data.length - 1].caudal -
                      item.device_data[0].caudal
                    ).toFixed(3)
                  ) +
                  " (" +
                  item.device_product.device_class.measurement_unit +
                  ")"
                }}
              </span>
              <span v-else>0</span>
            </template>
          </v-data-table>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import BackendApi from "@/services/backend.service";
import xlsx from "json-as-xlsx";

import jspdf from "jspdf";
import html2canvas from "html2canvas";
import moment from "moment";

export default {
  data() {
    return {
      telecontrol: null,
      services: [],
      serviceSelected: null,
      serviceSelected2: null,
      typeServices: [],
      typeServiceSlected: null,
      userN1SelectedId: null,
      usersN1: [],
      products: ["sadihasd", "salknda", "as98dsdasd", "sa8d8as9d"],
      color: { hex: null },
      isBrandBlank: false,
      dialog: false,
      headers: [
        { text: "device_id", value: "device_external_id" },
        { text: "Alias", value: "alias" },
        { text: "Id usuario N2", value: "id_user_n2" },
        { text: "# Suministro", value: "suministro" },
        { text: "Usuario N2", value: "user_n2" },
        { text: "Tipo", value: "tipo" },
        { text: "Fecha", value: "createdAt" },
        {
          text: "imp/",
          value: "coefficient",
        },
        { text: "Pulsos", value: "pulsos" },
        { text: "Actuador", value: "actuador" },
        { text: "Consumo()", value: "value_in_momment" },
        { text: "Caudal Promedio", value: "caudal" },
      ],
      items: [],
      modalDateRangeStart: null,
      dateStart: null,
      maxDateStart: null,
      modalDateRangeEnd: null,
      dateEnd: null,
      minDateEnd: null,
      isLoadingDownload: false,
      isLoadingDownloadPdf: false,
    };
  },
  created() {
    this.getService();
    this.getUsersN1();
  },
  mounted() {
    this.typeServices = this.$store.state.report.typeServices;
  },
  computed: {
    unidad() {
      const measurementUnit = this.serviceSelected2.measurement_unit
        ? this.serviceSelected2.measurement_unit.includes("M3")
          ? "m3"
          : "Kw"
        : "";
      return measurementUnit;
    },
    moment() {
      return moment;
    },
    isAdmin() {
      return this.$cookies.get("user").profileId === 2;
    },
  },
  methods: {
    saveDateStart() {
      this.$refs.dialogStart.save(this.dateStart);
      this.minDateEnd = new Date(this.dateStart).toISOString();
    },
    saveDateEnd() {
      this.$refs.dialogEnd.save(this.dateEnd);
      this.maxDateStart = new Date(this.dateEnd).toISOString();
    },
    hasStatusControl(item) {
      if (item.device_data.length === 0) return false;
      return item.device_data[item.device_data.length - 1].status_ctlr;
    },
    hasStatusControl2(item) {
      if (item.device_data.length === 0) {
        if (item.device_product.deviceClassId === 2) {
          return "Corte";
        } else {
          return "Cerrado";
        }
      } else {
        if (item.device_product.deviceClassId === 2) {
          if (item.device_data[item.device_data.length - 1].status_ctlr) {
            return "Energizado";
          } else {
            return "Corte";
          }
        } else {
          if (item.device_data[item.device_data.length - 1].status_ctlr) {
            return "Abierto";
          } else {
            return "Cerrado";
          }
        }
      }
    },
    hasValue_in_momment(item) {
      if (item.device_data.length === 0) return false;
      return item.device_data[item.device_data.length - 1].value_in_momment;
    },
    hasPulsos(item) {
      if (item.device_data.length === 0) return false;
      return item.device_data[item.device_data.length - 1].pulsos;
    },
    hasCaudal(item) {
      if (item.device_data.length === 0) return false;
      return item.device_data[item.device_data.length - 1].caudal;
    },
    searchDeviceData() {
      if (this.$refs.form.validate()) {
        const payload = {
          userN1SelectedId: this.userN1SelectedId,
          service: this.serviceSelected,
          tipeService: this.typeServiceSlected,
          startDate: this.dateStart,
          endDate: this.dateEnd,
        };
        BackendApi.post("/device/deviceForTotalReport", payload)
          .then((response) => {
            if (response.data.success) {
              this.items = response.data.data;
              console.log(this.items);
              const hasTrueValue = this.items.some(
                (item) => item.device_product.telecontrol === true
              );
              console.log(hasTrueValue);
              if (hasTrueValue) {
                const actuadorHeader = { text: "Actuador", value: "actuador" };
                const actuadorExists = this.headers.some(
                  (header) => header.text === "Actuador"
                );

                if (!actuadorExists) {
                  this.headers.push(actuadorHeader);
                }
              } else {
                this.headers = this.headers.filter(
                  (header) => header.text !== "Actuador"
                );
              }
            }
          })
          .catch(() => {});
      }
    },
    getService() {
      BackendApi.get("/deviceClass/deviceClassById")
        .then((response) => {
          if (response.data.success) {
            this.services = response.data.data.device_classes;
          }
        })
        .catch(() => {});
    },
    getUsersN1() {
      BackendApi.get("/user/usersN1")
        .then((response) => {
          if (response.data.success) {
            this.usersN1 = response.data.data;
          }
        })
        .catch(() => {});
    },
    changeService() {
      this.serviceSelected2 = this.services.find(
        (service) => service.id === this.serviceSelected
      );
      // this.items = [];
      this.headers = [
        { text: "device_id", value: "device_external_id" },
        { text: "Alias", value: "alias" },
        { text: "Id usuario N2", value: "id_user_n2" },
        { text: "# Suministro", value: "suministro" },
        { text: "Usuario N2", value: "user_n2" },
        { text: "Tipo", value: "tipo" },
        { text: "Fecha", value: "createdAt" },
        {
          text: `Imp/${this.unidad}`,
          value: "coefficient",
        },
        { text: "Pulsos", value: "pulsos" },
        { text: "Actuador", value: "actuador" },
        {
          text: `Consumo(${this.unidad})`,
          value: "value_in_momment",
        },
        {
          text: `${this.serviceSelected === 2 ? "Perfil promedio" : "Caudal"}(${
            this.unidad
          }/h)`,
          value: "caudal",
        },
      ];
    },
    async downloadPdf() {
      this.isLoadingDownloadPdf = true;

      // eslint-disable-next-line no-unused-vars
      let services = "";

      this.services.forEach((element, index) => {
        if (index > 0) {
          services += element.name + ", ";
        }
      });

      const options = {
        scale: 3,
      };
      var logo;
      await html2canvas(document.getElementById("logo-teca")).then((canvas) => {
        logo = canvas.toDataURL("image/png");
      });

      await html2canvas(document.getElementById("table-reports"), options)
        .then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const doc = new jspdf("p", "pt", "a4");
          const bufferX = 25;
          const bufferY = 340;
          const imgProps = doc.getImageProperties(imgData);
          const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
          const sizeHead = 16;

          doc.addImage(logo, "JPEG", 189, 10, 200, 100, undefined, "FAST");

          doc.setFontSize(sizeHead);
          const optionsHeader = {
            align: "center",
            font: "helvetica",
          };
          doc.setFontSize(11);
          // doc.text(
          //   "RUC: " + this.$cookies.get("user").ruc,
          //   285,
          //   90,
          //   optionsHeader
          // );
          doc.setFontSize(18);
          doc.text("Reportes Totales", 290, 130, optionsHeader);
          doc.setFontSize(11);
          doc.text("Generado por: " + this.$cookies.get("user").name, 25, 160);
          doc.setFontSize(11);
          doc.text(
            "Fecha: " + moment(new Date()).format("DD/MM/YYYY"),
            25,
            180
          );
          doc.text(
            "Contacto:" +
              this.$cookies.get("user").phone +
              " mail: " +
              this.$cookies.get("user").email,
            25,
            200
          );
          doc.text(
            "Período: " +
              moment(this.dateStart).format("DD/MM/YYYY") +
              " - " +
              moment(this.dateEnd).format("DD/MM/YYYY"),
            25,
            220
          );
          doc.text(
            this.serviceSelected == 2
              ? "Servicio: Energia"
              : this.serviceSelected == 1
              ? "Servicio: Agua"
              : "Servicio: Gas",
            25,
            240
          );

          doc.setFontSize(14);
          doc.text("DETALLES: ", 255, 290);

          doc.addImage(
            imgData,
            "JPEG",
            bufferX,
            bufferY,
            pdfWidth,
            pdfHeight,
            undefined,
            "FAST"
          );

          doc.save(
            `Reporte Totales ${moment(this.dateStart).format(
              "DD/MM/YYYY"
            )} - ${moment(this.dateEnd).format("DD/MM/YYYY")}.pdf`
          );
          this.isLoadingDownloadPdf = false;
        })
        .catch(() => {
          this.isLoadingDownloadPdf = false;

          // this.$store.dispatch('app/showToast', 'No se pudo enviar el informe al email del usuario con datos de la campaña creada, verifique su correo')
        });
    },
    async downloadExcel() {
      try {
        this.isLoadingDownload = true;
        this.items.forEach((element) => {
          element.dateStart = this.dateStart;
          element.dateEnd = this.dateEnd;
        });
        const data = [
          {
            sheet: "Reporte Total",
            columns: [
              { label: "Id", value: (row) => row.device_external_id },
              { label: "Alias", value: "alias" },
              {
                label: "Id usuario N2",
                value: (row) => (row.user ? row.user.id_usuario : ""),
              },
              { label: "# Suministro", value: "suministro" },
              {
                label: "Usuario N2",
                value: (row) => (row.user ? row.user.email : ""),
              },
              {
                label: "Tipo",
                value: (row) =>
                  row.device_product.telecontrol === false &&
                  row.device_product.telemetry === true
                    ? "Medición"
                    : row.device_product.telecontrol === true &&
                      row.device_product.telemetry === false
                    ? "Telecontrol"
                    : "Medición + Telecontrol",
              },
              { label: "Fecha inicio", value: "dateStart" },
              { label: "Fecha fin", value: "dateEnd" },
              { label: `Imp/${this.unidad}`, value: "coefficient" },
              {
                label: `Consumo(${this.unidad})`,
                value: (row) =>
                  this.hasValue_in_momment(row)
                    ? row.device_data[row.device_data.length - 1]
                        .value_in_momment
                    : "0",
              },
              {
                label: `Pulsos`,
                value: (row) =>
                  row.device_data[row.device_data.length - 1].pulsos
                    ? row.device_data[row.device_data.length - 1].pulsos
                    : "0",
              },
              {
                label: `${
                  this.serviceSelected === 2
                    ? "Perfil promedio"
                    : "Caudal Promedio"
                }(${this.unidad}/h)`,
                value: (row) =>
                  this.hasCaudal(row)
                    ? row.device_data[row.device_data.length - 1].caudal
                    : "0",
              },
            ],
            content: [...this.items],
          },
        ];
        const hasTelecontrol = this.items.some(
          (item) => item.device_product.telecontrol !== false
        );
        if (hasTelecontrol) {
          const actuadorColumn = {
            label: "Actuador",
            value: (row) =>
              this.hasStatusControl(row) ? "Abierto" : "Cerrado",
          };

          data[0].columns.splice(8, 0, actuadorColumn);
        }
        const settings = {
          sheetName: "Data",
          fileName: "Reporte Total",
        };

        await xlsx(data, settings);
        this.isLoadingDownload = false;
      } catch (error) {
        this.isLoadingDownload = false;
      }
    },
  },
};
</script>
